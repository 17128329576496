<template>
    <div
        v-if="pageData.loading === 'DONE'"
        class="h-100 container-fluid container-small py-5"
    >
        <form-login
            v-if="!form.loading"
            class="text-center"
            :next-url="nextUrl"
        />
        <span
            v-else
            role="status"
            aria-hidden="true"
            class="spinner-border "
        />
    </div>
</template>

<script>
import FormLogin from '../components/FormLogin.vue';
import { authMixin, page } from '../utils/mixins';

export default {
    name: 'PageLogin',

    components: {
        FormLogin,
    },

    mixins: [
        page(),
        authMixin,
    ],

    data: () => ({
        form: {
            loading: null,
        },
    }),

    created() {
        const { query: { token, 'next-url': nextUrl = '/' }, hash } = this.$route;

        this.nextUrl = (token && !nextUrl) ? '/account' : nextUrl + hash;

        if (token) {
            this.form.loading = true;
            this.loginUser(token, '', 'jwt')
                .catch(() => { this.form.loading = false; });
        } else if (this.$store.getters['user/isLoggedin']) {
            const redirectObj = { url: this.nextUrl };
            if (this.$ssrContext) throw redirectObj;
            else this.$router.push(this.nextUrl);
        }
    },
};
</script>
