var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageData.loading === "DONE"
    ? _c(
        "div",
        { staticClass: "h-100 container-fluid container-small py-5" },
        [
          !_vm.form.loading
            ? _c("form-login", {
                staticClass: "text-center",
                attrs: { "next-url": _vm.nextUrl },
              })
            : _c("span", {
                staticClass: "spinner-border",
                attrs: { role: "status", "aria-hidden": "true" },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }